<template>
	<div>
		<el-row :gutter="20">
			<el-col :span="8" style="min-width: 375px; padding: 35px; background: #f6f8fc;">
				<div style="display: table; margin: 0 auto;">
					<component
						ref="myLucky"
						:is="form.type"
						:width="form.config.width"
						:height="form.config.height"
						:rows="form.config.rows"
						:cols="form.config.cols"
						:prizes="form.config.prizes"
						:blocks="form.config.blocks"
						:slots="form.config.slots"
						:buttons="form.config.buttons"
						:activeStyle="form.config.activeStyle"
						:defaultConfig="form.config.defaultConfig"
						@start="startCallback"
						@end="endCallback"/>
				</div>
			</el-col>
			<el-col :span="16">
				<el-form ref="form" label-position="top" :model="form" :rules="rules" :size="theme.size" :style="{height: height+'px', overflowY: 'auto', overflowX: 'hidden'}">
					<el-form-item label="抽奖名称" prop="name">
						<el-input v-model="form.name">
							<el-select style="width: 100px;" slot="prepend" v-model="form.type" placeholder="请选择类型" @change="changeType">
								<el-option label="大转盘" value="LuckyWheel"></el-option>
								<el-option label="九宫格" value="LuckyGrid"></el-option>
								<el-option label="老虎机" value="SlotMachine"></el-option>
							</el-select>
						</el-input>
					</el-form-item>
					<!-- 九宫格配置 -->
					<el-form-item label="设置布局">
						<el-row :gutter="4">
							<el-col :span="4">
								<el-input v-model="form.config.width"><template slot="append">宽</template></el-input>
							</el-col>
							<el-col :span="4">
								<el-input v-model="form.config.height"><template slot="append">高</template></el-input>
							</el-col>
							<template v-if="form.type ==='LuckyGrid'">
								<el-col :span="4">
									<el-input v-model="form.config.rows"><template slot="append">行</template></el-input>
								</el-col>
								<el-col :span="4">
									<el-input v-model="form.config.cols"><template slot="append">列</template></el-input>
								</el-col>
							</template>
						</el-row>
					</el-form-item>
					<el-form-item label="背景配置">
						<div slot="label">背景配置<el-button type="text" style="margin-left: 5px;" @click="addOption('blocks')">添加</el-button></div>
						<el-row :gutter="4" v-for="(block, b) in form.config.blocks" :key="b">
							<el-col :span="1">
								<el-form-item label="背景">
									<el-popconfirm slot="label" title="确定删除背景吗？" @confirm="removeOption('blocks', block)">
										<el-button slot="reference" type="text">删除</el-button>
									</el-popconfirm>
									<el-color-picker v-model="block.background" show-alpha></el-color-picker>
								</el-form-item>
							</el-col>
							<el-col :span="2">
								<el-form-item label="内边距"><el-input v-model="block.padding"></el-input></el-form-item>
							</el-col>
							<!-- 此配置在九宫格才有 -->
							<el-col :span="2" v-if="form.type === 'LuckyGrid'">
								<el-form-item label="圆角半径"><el-input v-model="block.borderRadius"></el-input></el-form-item>
							</el-col>
							<el-col :span="2" v-if="!block.imgs"><el-button type="text" @click="addImage('blocks', b)">添加图片</el-button></el-col>
							<template v-else>
								<el-col :span="2">
									<el-form-item label="背景图片">
										<el-popconfirm slot="label" title="确定删除图片吗？" @confirm="removeImage('blocks', b)">
											<el-button slot="reference" type="text">删除图片</el-button>
										</el-popconfirm>
										<el-avatar shape="square" :src="block.imgs[0].src"></el-avatar>
									</el-form-item>
								</el-col>
								<el-col :span="2">
									<el-form-item label="图片位置"><el-input v-model="block.imgs[0].top"></el-input></el-form-item>
								</el-col>
								<el-col :span="2">
									<el-form-item label="图片宽度"><el-input v-model="block.imgs[0].width"></el-input></el-form-item>
								</el-col>
								<el-col :span="2">
									<el-form-item label="图片高度"><el-input v-model="block.imgs[0].height"></el-input></el-form-item>
								</el-col>
								<!-- 此配置在大转盘才有 -->
								<el-col :span="2" v-if="form.type ==='LuckyWheel'">
									<el-form-item label="跟随旋转"><el-switch v-model="block.imgs[0].rotate"></el-switch></el-form-item>
								</el-col>
							</template>
						</el-row>
					</el-form-item>
					<el-alert title="奖品配置说明" type="warning" :closable="false">
						<p>配置最廉价奖品数量最大值。</p>
						<p>配置价值让大奖在前半段时间内不被抽到</p>
					</el-alert>
					<el-form-item label="奖品配置">
						<div slot="label">奖品配置<el-button type="text" style="margin-left: 5px;" @click="addOption('prizes')">添加</el-button></div>
						<el-row v-for="(prize, p) in form.config.prizes" :key="p" :gutter="4">
							<el-col :span="1">
								<el-form-item label="背景">
									<el-popconfirm slot="label" title="确定删除背景吗？" @confirm="removeOption('prizes', prize)">
										<el-button slot="reference" type="text">删除</el-button>
									</el-popconfirm>
									<el-color-picker v-model="prize.background" show-alpha></el-color-picker>
								</el-form-item>
							</el-col>
							<!-- 九宫格配置 -->
							<template v-if="form.type ==='LuckyGrid'">
								<el-col :span="1">
									<el-form-item label="X坐标">
										<el-input v-model="prize.x"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="1">
									<el-form-item label="Y坐标">
										<el-input v-model="prize.y"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="1">
									<el-form-item label="X合并">
										<el-input v-model="prize.col"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="1">
									<el-form-item label="Y合并">
										<el-input v-model="prize.row"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="2">
									<el-form-item label="圆角半径">
										<el-input v-model="prize.borderRadius"></el-input>
									</el-form-item>
								</el-col>
							</template>
							<el-col :span="2">
								<el-form-item label="中奖概率" :prop="'config.prizes.'+p+'.range'" :rules="[{ required: true, message: '必填概率'}, { type: 'number', message: '请填数字', transform: (v) => Number(v)}]">
										<el-input v-model="prize.range"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="2">
								<el-form-item label="投放数量" :prop="'config.prizes.'+p+'.count'" :rules="[{ required: true, message: '必填数量'}, { type: 'number', message: '请填数字', transform: (v) => Number(v)}]">
										<el-input v-model="prize.count"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="2">
								<el-form-item label="价值(分)" :prop="'config.prizes.'+p+'.cost'" :rules="[{ required: true, message: '必填价值'}, { type: 'number', message: '请填数字', transform: (v) => Number(v)}]">
										<el-input v-model="prize.cost"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="2" v-if="!prize.fonts"><el-button type="text" @click="addText('prizes', p)">添加文字</el-button></el-col>
							<template v-else>
								<el-col :span="2">
									<el-form-item label="文字" :prop="'config.prizes.'+p+'.fonts.0.text'" :rules="[{ required: true, message: '必填文字'}]">
										<el-popconfirm slot="label" title="确定删除文字吗？" @confirm="removeText('prizes', p)">
											<el-button slot="reference" type="text">删除文字</el-button>
										</el-popconfirm>
										<el-input v-model="prize.fonts[0].text"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="2">
									<el-form-item label="文字颜色"><el-color-picker v-model="prize.fonts[0].fontColor" show-alpha></el-color-picker></el-form-item>
								</el-col>
								<el-col :span="2">
									<el-form-item label="文字位置"><el-input v-model="prize.fonts[0].top"></el-input></el-form-item>
								</el-col>
								<el-col :span="2">
									<el-form-item label="文字大小"><el-input v-model="prize.fonts[0].fontSize"></el-input></el-form-item>
								</el-col>
								<el-col :span="2">
									<el-form-item label="文字粗细"><el-input v-model="prize.fonts[0].fontWeight"></el-input></el-form-item>
								</el-col>
							</template>
							<el-col :span="2" v-if="!prize.imgs"><el-button type="text" @click="addImage('prizes', p)">添加图片</el-button></el-col>
							<template v-else>
								<el-col :span="2">
									<el-form-item label="背景图片">
										<el-popconfirm slot="label" title="确定删除图片吗？" @confirm="removeImage('prizes', p)">
											<el-button slot="reference" type="text">删除图片</el-button>
										</el-popconfirm>
										<el-avatar shape="square" :src="prize.imgs[0].src"></el-avatar>
									</el-form-item>
								</el-col>
								<el-col :span="2">
									<el-form-item label="图片位置"><el-input v-model="prize.imgs[0].top"></el-input></el-form-item>
								</el-col>
								<el-col :span="2">
									<el-form-item label="图片宽度"><el-input v-model="prize.imgs[0].width"></el-input></el-form-item>
								</el-col>
								<el-col :span="2">
									<el-form-item label="图片高度"><el-input v-model="prize.imgs[0].height"></el-input></el-form-item>
								</el-col>
							</template>
						</el-row>
					</el-form-item>
					<el-form-item label="按钮配置">
						<div slot="label">按钮配置<el-button type="text" style="margin-left: 5px;" @click="addOption('buttons')">添加</el-button></div>
						<el-row :gutter="4" v-for="(button, b) in form.config.buttons" :key="b">
							<el-col :span="1">
								<el-form-item label="背景">
									<el-popconfirm slot="label" title="确定删除背景吗？" @confirm="removeOption('buttons', button)">
										<el-button slot="reference" type="text">删除</el-button>
									</el-popconfirm>
									<el-color-picker v-model="button.background" show-alpha></el-color-picker>
								</el-form-item>
							</el-col>
							<!-- 大转盘配置 -->
							<template v-if="form.type ==='LuckyWheel'">
								<el-col :span="2">
									<el-form-item label="显示指针"><el-switch v-model="button.pointer"></el-switch></el-form-item>
								</el-col>
								<el-col :span="2">
									<el-form-item label="按钮半径">
										<el-input v-model="button.radius"></el-input>
									</el-form-item>
								</el-col>
							</template>
							<!-- 九宫格配置 -->
							<template v-if="form.type ==='LuckyGrid'">
								<el-col :span="1">
									<el-form-item label="X坐标">
										<el-input v-model="button.x"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="1">
									<el-form-item label="Y坐标">
										<el-input v-model="button.y"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="1">
									<el-form-item label="X合并">
										<el-input v-model="button.col"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="1">
									<el-form-item label="Y合并">
										<el-input v-model="button.row"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="2">
									<el-form-item label="圆角半径">
										<el-input v-model="button.borderRadius"></el-input>
									</el-form-item>
								</el-col>
							</template>
							<el-col :span="2" v-if="!button.fonts"><el-button type="text" @click="addText('buttons', b)">添加文字</el-button></el-col>
							<template v-else>
								<el-col :span="2">
									<el-form-item label="文字" :prop="'config.buttons.'+b+'.fonts.0.text'" :rules="[{ required: true, message: '必填文字'}]">
										<el-popconfirm slot="label" title="确定删除文字吗？" @confirm="removeText('buttons', b)">
											<el-button slot="reference" type="text">删除文字</el-button>
										</el-popconfirm>
										<el-input v-model="button.fonts[0].text"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="2">
									<el-form-item label="文字颜色"><el-color-picker v-model="button.fonts[0].fontColor" show-alpha></el-color-picker></el-form-item>
								</el-col>
								<el-col :span="2">
									<el-form-item label="文字位置"><el-input v-model="button.fonts[0].top"></el-input></el-form-item>
								</el-col>
								<el-col :span="2">
									<el-form-item label="文字大小"><el-input v-model="button.fonts[0].fontSize"></el-input></el-form-item>
								</el-col>
								<el-col :span="2">
									<el-form-item label="文字粗细"><el-input v-model="button.fonts[0].fontWeight"></el-input></el-form-item>
								</el-col>
							</template>
							<el-col :span="2" v-if="!button.imgs"><el-button type="text" @click="addImage('buttons', b)">添加图片</el-button></el-col>
							<template v-else>
								<el-col :span="2">
									<el-form-item label="背景图片">
										<el-popconfirm slot="label" title="确定删除图片吗？" @confirm="removeImage('buttons', b)">
											<el-button slot="reference" type="text">删除图片</el-button>
										</el-popconfirm>
										<el-avatar shape="square" :src="button.imgs[0].src"></el-avatar>
									</el-form-item>
								</el-col>
								<el-col :span="2">
									<el-form-item label="图片位置"><el-input v-model="button.imgs[0].top"></el-input></el-form-item>
								</el-col>
								<el-col :span="2">
									<el-form-item label="图片宽度"><el-input v-model="button.imgs[0].width"></el-input></el-form-item>
								</el-col>
								<el-col :span="2">
									<el-form-item label="图片高度"><el-input v-model="button.imgs[0].height"></el-input></el-form-item>
								</el-col>
							</template>
						</el-row>
					</el-form-item>
						<!-- 此配置在九宫格才有 -->
					<el-form-item label="中奖样式" v-if="form.type === 'LuckyGrid'">
						<el-row :gutter="4">
							<el-col :span="1">
								<el-form-item label="字体">
									<el-color-picker v-model="form.config.activeStyle.fontColor" show-alpha></el-color-picker>
								</el-form-item>
							</el-col>
							<el-col :span="2">
								<el-form-item label="字体大小">
									<el-input v-model="form.config.activeStyle.fontSize"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="2">
								<el-form-item label="字体粗细">
									<el-input v-model="form.config.activeStyle.fontWeight"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="1">
								<el-form-item label="背景">
									<el-color-picker v-model="form.config.activeStyle.background" show-alpha></el-color-picker>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form-item>
					<el-form-item label="抽奖时间" prop="time" v-if="!form.published_at">
						<el-date-picker v-model="form.time" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="抽奖规则" prop="rule">
						<el-input v-model="form.rule" type="textarea" rows="5" placeholder="请输入内容" maxlength="368" show-word-limit></el-input>
					</el-form-item>
					<el-form-item label-width="0">
						<el-button type="primary" @click="submitForm('form')">{{form.id?'保存产品':'立即创建'}}</el-button>
						<el-button @click="resetForm('form')">重置</el-button>
					</el-form-item>
				</el-form>
			</el-col>
		</el-row>
		<el-dialog title="图片素材库" :visible.sync="dialogVisible">
			<el-upload accept="image/gif, image/png, image/jpeg" :action="$config.APIHOST+'uploads'" :show-file-list="false" :on-success="onSuccessPic" :data="{type: 'luckydraws'}" multiple>
				<el-button size="small" type="primary">点击上传</el-button>
				<div slot="tip" class="el-upload__tip">只能上传jpg/png/gif文件，且不超过500kb</div>
			</el-upload>
			<el-row :gutter="4">
				<el-col class="materials" v-for="(material, m) in materials" :key="m" :span="4">
					<el-image :class="['material', material.checked?'checked':'']" :src="material.src" fit="fit" @click="checkImg(material)"></el-image>
					<h5>{{material.name}}</h5>
				</el-col>
			</el-row>
			<div slot="footer">
				<el-button type="primary" icon="el-icon-arrow-left" :size="theme.size" plain>上一页</el-button>
				<el-button type="primary" :size="theme.size" plain>下一页<i class="el-icon-arrow-right el-icon--right"></i></el-button>
				<el-button @click="dialogVisible = false" :size="theme.size">取 消</el-button>
				<el-button type="primary" @click="confirmImage" :size="theme.size">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<style>
	.materials {
		margin-top: 10px;
		text-align: center;
	}
	.materials .material {
		width: 80px;
		height: 80px;
		cursor: pointer;
		border-radius: 5px;
		border: 2px solid #fff;
	}
	.materials .material.checked {
		border-color: #409eff;
	}
</style>

<script>
	import { mapState } from 'vuex';
	export default {
		computed: {
			...mapState(['theme']),
			height () {
				return this.theme.height - 160;
			}
		},
		data() {
			return {
				dialogVisible: false,
				option: 'blocks',
				option_i: 0,
				materials: [],
				form: {
					type: 'LuckyWheel',
				},
				params: {
					perPage: 18
				},
				rules: {
					name: [
						{ required: true, message: '请输入抽奖名称！', trigger: 'blur' }
					],
					time: [
						{ required: true, message: '请选择抽奖时间！', trigger: 'blur' }
					],
					rule: [
						{ required: true, message: '请输入抽奖规则！', trigger: 'blur' }
					]
				},
				init: {
					LuckyWheel: {
						// 组件宽度。默认300px
						width: '300px',
						// 组件高度。默认300px
						height: '300px',
						// 背景区域。支持多层，大转盘始终是一个圆
						blocks: [
							{ padding: 0, background: '#869cfa' }
						],
						// 奖品列表
						prizes: [
							{ background: '#e9e8fe', range: 0, count: 0, cost: 0 },
							{ background: '#b8c5f2', range: 0, count: 0, cost: 0 },
							{ background: '#e9e8fe', range: 0, count: 0, cost: 0 },
							{ background: '#b8c5f2', range: 0, count: 0, cost: 0 },
							{ background: '#e9e8fe', range: 0, count: 0, cost: 0 },
							{ background: '#b8c5f2', range: 0, count: 0, cost: 0 }
						],
						// 中心按钮。支持层叠
						buttons: [
							{ radius: '30%', pointer: true, background: '#869cfa' }
						]
					},
					LuckyGrid: {
						width: '300px',
						height: '300px',
						rows: '3',
						cols: '3',
						blocks: [
							{ padding: '10px', background: '#869cfa', borderRadius: 0 },
							{ padding: '10px', background: '#e9e8fe', borderRadius: 0 }
						],
						prizes: [
							{ x: 0, y: 0, row: 1, col: 1, borderRadius: '0px', background: '#b8c5f2', range: 0, count: 0, cost: 0 },
							{ x: 1, y: 0, row: 1, col: 1, borderRadius: '0px', background: '#b8c5f2', range: 0, count: 0, cost: 0 },
							{ x: 2, y: 0, row: 1, col: 1, borderRadius: '0px', background: '#b8c5f2', range: 0, count: 0, cost: 0 },
							{ x: 2, y: 1, row: 1, col: 1, borderRadius: '0px', background: '#b8c5f2', range: 0, count: 0, cost: 0 },
							{ x: 2, y: 2, row: 1, col: 1, borderRadius: '0px', background: '#b8c5f2', range: 0, count: 0, cost: 0 },
							{ x: 1, y: 2, row: 1, col: 1, borderRadius: '0px', background: '#b8c5f2', range: 0, count: 0, cost: 0 },
							{ x: 0, y: 2, row: 1, col: 1, borderRadius: '0px', background: '#b8c5f2', range: 0, count: 0, cost: 0 },
							{ x: 0, y: 1, row: 1, col: 1, borderRadius: '0px', background: '#b8c5f2', range: 0, count: 0, cost: 0 }
						],
						buttons: [
							{ x: 1, y: 1, row: 1, col: 1, borderRadius: '0px', background: '#9c9dd8'}
						],
						// 中奖标记样式
						activeStyle: {
							// 字体颜色。默认黑色
							fontColor: '#000',
							// 字体大小。默认22px
							fontSize: '22px',
							// 字体粗细。默认400
							fontWeight: '400',
							// 背景颜色。默认透明色
							background: '#000'
						}
					},
					SlotMachine: {
						width: '240px',
						height: '180px',
						blocks: [
							{ padding: '10px', background: '#869cfa', borderRadius: 0 },
							{ padding: '10px', background: '#e9e8fe', borderRadius: 0 }
						],
						slots: [
							{ order: [0, 1, 2] },
							{ order: [2, 0, 1] },
							{ order: [1, 2, 0] }
						],
						prizes: [
							{ background: '#b6c8f6', borderRadius: '10px', range: 0, count: 0, cost: 0 },
							{ background: '#8c9eed', borderRadius: '10px', range: 0, count: 0, cost: 0 },
							{ background: '#6980e3', borderRadius: '10px', range: 0, count: 0, cost: 0 }
						],
						defaultConfig: {
							rowSpacing: '10px',
							colSpacing: '10px'
						}
					}
				}
			};
		},
		methods: {
			changeType () {
				this.form = { ...this.form, config: JSON.parse(JSON.stringify(this.init[this.form.type]))};
			},
			onSuccessPic (r, f, l) {
				console.log(r, f, l);
				this.getPicmaterials();
			},
			submitForm (f) {
				this.$refs[f].validate(async (valid) => {
					if (!valid) return false;
					// console.log(this.form);
					const res = await this.$http.post(this.$api.URI_LUCKYDRAWS, this.form);
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message({
						type: 'success',
						duration: 1000,
						message: msg,
						onClose: () => {
							this.$utils.removeMenuTab(this.$router.currentRoute.path, '/luckydraws');
						}
					});
				})
			},
			addOption (t) {
				let obj = this.form.config[t];
				let last = obj.length ? obj[obj.length-1] : this.init[this.form.type][t][0];
				obj.push(JSON.parse(JSON.stringify(last)));
				this.form.config[t] = JSON.parse(JSON.stringify(obj));
			},
			removeOption (t, i) {
				let obj = this.form.config[t];
				var index = obj.indexOf(i);
				if (t == 'prizes' && obj.length <= 3) return this.$message.error('最少要保留3项奖品');
				if (index !== -1) {
					obj.splice(index, 1)
				}
				this.form.config[t] = JSON.parse(JSON.stringify(obj));
			},
			addImage (t, i) {
				this.getPicmaterials();
				this.dialogVisible = true;
				this.option = t;
				this.option_i = i;
			},
			removeImage (t, i) {
				delete this.form.config[t][i].imgs;
				this.form.config[t] = JSON.parse(JSON.stringify(this.form.config[t]));
			},
			addText (t, i) {
				let obj = this.form.config[t][i];
				obj.fonts = [{
					text: '必填文字',
					top: '0%',
					fontSize: '22px',
					fontColor: '#000',
					fontWeight: '400',
				}];
				this.form.config[t] = JSON.parse(JSON.stringify(this.form.config[t]));
			},
			removeText (t, i) {
				delete this.form.config[t][i].fonts;
				this.form.config[t] = JSON.parse(JSON.stringify(this.form.config[t]));
			},
			checkImg (img) {
				let materials = this.materials;
				materials.map((material) => {
					material.checked = false;
					if (material.src == img.src) {
						material.checked = true;
					}
				});
				this.materials = JSON.parse(JSON.stringify(materials));
			},
			confirmImage () {
				this.materials.map((material) => {
					if (material.checked) {
						let obj = this.form.config[this.option][this.option_i];
						obj.imgs = [{
							src: material.src,
							top: this.option == 'buttons'?'-100%':'0%',
							width: '100%',
							height: ''
						}];
						this.form.config[this.option] = JSON.parse(JSON.stringify(this.form.config[this.option]));
					}
				});
				this.dialogVisible = false;
			},
			async getPicmaterials (params) {
				const res = await this.$http.get(this.$api.URI_PICMATERIALS, { params });
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.materials = result.data;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			// 点击抽奖按钮会触发star回调
			startCallback () {
				// 调用抽奖组件的play方法开始游戏
				this.$refs.myLucky.play()
				// 模拟调用接口异步抽奖
				setTimeout(() => {
					// 假设后端返回的中奖索引是0
					const index = 0
					// 调用stop停止旋转并传递中奖索引
					this.$refs.myLucky.stop(index)
				}, 3000)
			},
			// 抽奖结束会触发end回调
			endCallback () {
				this.$message.success('执行成功！此动作仅查看动画效果，建议保存使用用户端测试中奖率后再发布。');
			},
		},
		async created () {
			const params = this.$route.params;
			if (params.id) {
				const res = await this.$http.get(this.$api.URI_LUCKYDRAWS, {params: {action: 'create', ...params}});
				let result = res.data.result;
				// 格式化组件数据回显
				result.time = [new Date(result.begin_at), new Date(result.end_at)];
				this.form = result;
				if (result.id) return false;
			}
			this.form = { ...this.form, config: JSON.parse(JSON.stringify(this.init[this.form.type]))};
		}
	};
</script>